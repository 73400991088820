import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import donationReducer from './donationReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  donation: donationReducer
});

export default rootReducer;
