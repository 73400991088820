import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import * as serviceWorker from 'serviceWorker';
import { SettingsProvider } from 'context/SettingsContext';
import { restoreSettings } from 'utils/settings';

import { Provider } from 'react-redux';
import { configureStore } from './store';

import App from 'App';

enableES5();

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App/>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
