import produce from 'immer';
import { 
  FILTER_DONATION_FAILURE, FILTER_DONATION_SUCCESS, FILTER_DONATION_REQUEST,
  CANCEL_DONATION_FAILURE, CANCEL_DONATION_SUCCESS, CANCEL_DONATION_REQUEST,
  VERIFIED_DONATION_FAILURE, VERIFIED_DONATION_SUCCESS, VERIFIED_DONATION_REQUEST
} from 'actions/donationActions';

const initialState = {
  donations: null,
  meta: {},
  filters: {}
};

const donationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_DONATION_REQUEST: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.donations = null;
        draft.filters = filters;
        draft.status = FILTER_DONATION_REQUEST;
      });
    }

    case FILTER_DONATION_SUCCESS: {
      const { donations, meta } = action.payload;

      return produce(state, (draft) => {
        draft.donations = donations;
        draft.meta = meta;
        draft.status = FILTER_DONATION_SUCCESS;
      });
    }

    case FILTER_DONATION_FAILURE: {
      const { filters } = action.payload;

      return produce(state, (draft) => {
        draft.filters = filters;
        draft.status = FILTER_DONATION_FAILURE;
      });
    }

    case VERIFIED_DONATION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = VERIFIED_DONATION_REQUEST;
      });
    }

    case VERIFIED_DONATION_SUCCESS: {
      const { donation } = action.payload;

      return produce(state, (draft) => {
        draft.donations = state.donations.map(item => donation.id === item.id ? {...item, transactionStatus: 'VERIFIED'} : item);
        draft.status = VERIFIED_DONATION_FAILURE;
      });
    }

    case VERIFIED_DONATION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = VERIFIED_DONATION_FAILURE;
      });
    }

    case CANCEL_DONATION_REQUEST: {
      return produce(state, (draft) => {
        draft.status = CANCEL_DONATION_REQUEST;
      });
    }

    case CANCEL_DONATION_SUCCESS: {
      const { donation } = action.payload;

      return produce(state, (draft) => {
        draft.donations = state.donations.map(item => donation.id === item.id ? {...item, transactionStatus: 'CANCEL'} : item);
        draft.status = CANCEL_DONATION_FAILURE;
      });
    }

    case CANCEL_DONATION_FAILURE: {
      return produce(state, (draft) => {
        draft.status = CANCEL_DONATION_FAILURE;
      });
    }

    default: {
      return state;
    }
  }
};

export default donationReducer;
