/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardLayout from 'layouts/DashboardLayout';
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'components/AuthGuard';
import GuestGuard from 'components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app/dashboard" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/auth/LoginView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />,
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('views/dashboard/DashboardView')),
      },
      {
        exact: true,
        path: '/app/management/donations/:id',
        component: lazy(() => import('views/management/DetailDonationView')),
      },
      {
        exact: true,
        path: '/app/management/donations',
        component: lazy(() => import('views/management/ListDonationView')),
      },
      {
        exact: true,
        path: '/app/management/programs',
        component: lazy(() => import('views/management/ListProgramView')),
      },
      {
        exact: true,
        path: '/app/management/programs/create',
        component: lazy(() => import('views/management/CreateProgramView')),
      },
      {
        exact: true,
        path: '/app/management/programs/:id',
        component: lazy(() => import('views/management/UpdateProgramView')),
      },
      {
        exact: true,
        path: '/app/management/programs/:id/update/new',
        component: lazy(() => import('views/management/NewProgramUpdateView')),
      },
      {
        exact: true,
        path: '/app/management/regions',
        component: lazy(() => import('views/management/ListRegionView')),
      },
      {
        exact: true,
        path: '/app/management/regions/create',
        component: lazy(() => import('views/management/CreateRegionView')),
      },
      {
        exact: true,
        path: '/app/management/regions/:id',
        component: lazy(() => import('views/management/DetailRegionView')),
      },
      {
        exact: true,
        path: '/app/management/regions/:id/edit',
        component: lazy(() => import('views/management/EditRegionView')),
      },
      {
        exact: true,
        path: '/app/management/services',
        component: lazy(() => import('views/management/ListServiceView')),
      },
      {
        exact: true,
        path: '/app/management/services/:id',
        component: lazy(() => import('views/management/DetailServiceView')),
      },
      {
        exact: true,
        path: '/app/management/partners/create',
        component: lazy(() => import('views/management/CreatePartnerView')),
      },
      {
        exact: true,
        path: '/app/management/partners/:id',
        component: lazy(() => import('views/management/DetailPartnerView')),
      },
      {
        exact: true,
        path: '/app/management/partners/:id/edit',
        component: lazy(() => import('views/management/EditPartnerView')),
      },
      {
        exact: true,
        path: '/app/management/partners',
        component: lazy(() => import('views/management/ListPartnerView')),
      },
      {
        exact: true,
        path: '/app/management/referrals/create',
        component: lazy(() => import('views/management/CreateReferralView')),
      },
      {
        exact: true,
        path: '/app/management/referrals/:id',
        component: lazy(() => import('views/management/DetailReferralView')),
      },
      {
        exact: true,
        path: '/app/management/referrals/:id/edit',
        component: lazy(() => import('views/management/EditReferralView')),
      },
      {
        exact: true,
        path: '/app/management/referrals',
        component: lazy(() => import('views/management/ListReferralView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
