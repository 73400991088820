/* eslint-disable no-undef */
import axios from 'utils/axios';

class DonationService {
  fetchDonationUsingId = (donationId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/donation/${donationId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  fetchDonation = (page = 1, filters = {}) =>
    new Promise((resolve, reject) => {
      let filterQuery = '';

      for (const property in filters) {
        filterQuery =
          filterQuery + `&filter[${property}]=${filters[property] || ''}`;
      }

      axios
        .get(`/donation?page=${page}${filterQuery}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  verifiedDonation = (donation) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/donation/verified/${donation.id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  verifiedEDonation = (donation, params) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/donation/verified/${donation.id}/edonation`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  cancelDonation = (donation) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/donation/cancel/${donation.id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  editDonationAmount = (id, params) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/donation/amount/${id}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

    editDonationDetailAmount = (id, detailId, params) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/donation/${id}/detail/${detailId}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

}

const donationService = new DonationService();

export default donationService;
