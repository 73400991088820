import axios from 'axios';
import humps from './humps';
import { API_BASE_URL } from 'config';
import * as Sentry from '@sentry/browser';
import authService from 'services/authService';

const instance = axios.create({
  baseURL: API_BASE_URL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
});

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common.Accept = 'application/json';

instance.interceptors.request.use(config => {
    if (authService.isAuthenticated()) {
      const token = authService.getAccessToken();

      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(response => response, error => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  Sentry.captureException(error);

  if (!error.hasOwnProperty('response')) {
    return Promise.reject(error);
  }

  if (error.response.status === 401) {
    localStorage.clear();
  }

  return Promise.reject(error);
});

export default instance;
