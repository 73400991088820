import donationService from 'services/donationService';

export const FILTER_DONATION_REQUEST = '@donation/filter-donation-request';
export const FILTER_DONATION_SUCCESS = '@donation/filter-donation-success';
export const FILTER_DONATION_FAILURE = '@donation/filter-donation-failure';

export const CANCEL_DONATION_REQUEST = '@donation/cancel-donation-request';
export const CANCEL_DONATION_SUCCESS = '@donation/cancel-donation-success';
export const CANCEL_DONATION_FAILURE = '@donation/cancel-donation-failure';

export const VERIFIED_DONATION_REQUEST = '@donation/verified-donation-request';
export const VERIFIED_DONATION_SUCCESS = '@donation/verified-donation-success';
export const VERIFIED_DONATION_FAILURE = '@donation/verified-donation-failure';

export function fetchDonation(page = 1, filters = {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: FILTER_DONATION_REQUEST, payload: {filters} });

      const { data: donations, meta } = await donationService.fetchDonation(page, filters);

      dispatch({type: FILTER_DONATION_SUCCESS, payload: {donations, meta}});
      
    } catch (error) {
      dispatch({ type: FILTER_DONATION_FAILURE, payload: {filters} });
      throw error;
    }
  }
}

export function verifiedDonation(donation) {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFIED_DONATION_REQUEST });

      await donationService.verifiedDonation(donation);

      dispatch({type: VERIFIED_DONATION_SUCCESS, payload: {donation}});
      
    } catch (error) {
      dispatch({ type: VERIFIED_DONATION_FAILURE });
      throw error;
    }
  }
}

export function cancelDonation(donation) {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_DONATION_REQUEST });

      await donationService.cancelDonation(donation);

      dispatch({type: CANCEL_DONATION_SUCCESS, payload: {donation}});
      
    } catch (error) {
      dispatch({ type: CANCEL_DONATION_FAILURE });
      throw error;
    }
  }
}
